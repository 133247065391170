import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'MemoryCardsImagesCreate',
    data() {
        return {
            memoryCardImage: {
                name: null,
                level: 'easy'
            },
            fileImage: null
        }
    },
    mixins: [currentRouteMixin],
    components: {
        adminLayout
    },
    methods: {
        createMemoryCardImag() {
            const self = this
            let formData = new FormData()

            formData.append('name', self.memoryCardImage.name)
            formData.append('level', self.memoryCardImage.level)
            if (self.fileImage) formData.append('image', self.fileImage)

            httpAxios({
                url: self.$backendUrl + '/api/v1/memory-cards-images',
                method: 'POST',
                data: formData
            }).then(function () {
                self.$router.push({ name: 'admin.memorycards.images' })

                self.notifySuccess()
            })
        },
        handleFileImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileImage = null
            }

            this.fileImage = files[0]
        },
    }
}